<template>
  <div class="box box_mm">
    <div class="box_main">
      <div class="search_box">
        <div class="search_item">
          <span style="width: 0.8rem">文件标题：</span>
          <el-input
            v-model="searchinput"
            placeholder="请输入"
            style="width: 2rem"
          ></el-input>
        </div>
        <div class="search_item">
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button type="success" @click="addnew">新建公文处理单</el-button>
        </div>
      </div>
      <div class="box_cont">
        <div class="btn_list"></div>
        <div style="height: 76vh; box-sizing: border-box">
          <MyTable
            ref="zjlist"
            :tableData="tableData"
            :tableHeader="tableHeader"
            :showbm="true"
            :czwidth="180"
            @getdata="gettable"
            @changswitch="changswitch"
          >
            <template slot="btns" slot-scope="{ scope }">
              <el-button @click="showeidt(scope.row)" type="warning" size="mini"
                >编辑</el-button
              >
              <el-button @click="postdata(scope.row)" type="success" size="mini"
                >提交</el-button
              >
            </template>
          </MyTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchinput: "",
      tableHeader: [
        {
          prop: "id",
          name: "序",
          width: "50",
        },
        {
          prop: "name",
          name: "文件标题",
          width: "",
        },
        {
          prop: "ybuser_name",
          name: "拟办人",
          width: "120",
        },
        {
          prop: "psuser_name",
          name: "批示人",
          width: "120",
        },
        {
          prop: "wpsuser_name",
          name: "分管领导",
          width: "120",
        },
      ],
      tableData: [],
    };
  },
  mounted() {
    this.gettable();
  },
  methods: {
    postdata(row) {
      this.$confirm("您确定提交吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post({
            url: "/api/matter/tj",
            params: { id: row.id, type: 2 },
          }).then((res) => {
            this.$message.success("操作成功");
            this.gettable();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    showeidt(row) {
      this.$router.push("/system/get_file/edit?id=" + row.id);
    },
    changswitch(row) {
      this.$post({
        url: "/api/room/edit",
        params: row,
      }).then((res) => {
        this.$message({
          type: "success",
          message: "操作成功!",
        });
        this.gettable();
      });
    },
    search() {
      this.$refs.zjlist.inputpage = 1;
      this.gettable();
    },
    gettable() {
      this.$post({
        url: "/api/matter/wdsw",
        params: {
          name: this.searchinput,
          p: this.$refs.zjlist.inputpage,
          size: this.$refs.zjlist.pagesize,
          type: 1,
        },
      }).then((res) => {
        this.tableData = res.list;
        this.$refs.zjlist.total = res.count;
      });
    },
    addnew() {
      this.$router.push("/system/get_file/add");
    },
    showdate(row) {
      this.$refs.myroomdate.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "caogao.scss";
</style>
